import classes from "./UserProfile.module.css";

const UserProfile = ({ image }) => {
    return (
        <div 
            className={classes.UserProfile}
            style={{
                backgroundImage: `url(${image})`,
            }}>
        </div>
    );
};

export default UserProfile;