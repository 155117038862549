import Container from "../Components/Auth/UI/Container/Container";
import Header from "../Components/Common/Elements/Header/Header";
import Label from "../Components/Auth/Elements/Label/Label";
import InfoTag from "../Components/Auth/Elements/InfoTag/InfoTag";
import Input from "../Components/Auth/Elements/Input/Input";
import Box from "../Components/Auth/UI/Box/Box";
import Button from "../Components/Auth/Elements/Button/Button";
import { useLocation } from "react-router-dom";
import { useState } from "react"
import nullValidation from "../functions/nullValidation";
import ErrorInfo from "../Components/Common/Elements/ErrorInfo/ErrorInfo";
import sendPostRequest from "../functions/sendPostRequest";
import { useNavigate } from 'react-router-dom';
import LoaderSpinner from "../Components/Common/Elements/LoaderSpinner/LoaderSpinner";
import { API_URL } from "../API_URL";

const NewPasswordPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const userMail = params.get("usermail");
    const [newPassword,setNewPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const newPasswordChangeHandler = (event) => {
        setNewPassword(event.target.value);
    };

    const confirmPasswordChangeHandler = (event) =>
    {
        setConfirmPassword(event.target.value);
    };

    const passwordChangeHandler = async (event) =>
    {
        event.preventDefault();

        const isNull = nullValidation([newPassword,confirmPassword]);
        if(isNull)
        {
            alert("Please Enter Credentials");
        }
        else
        {
            setIsLoading(true);
            const url = `${API_URL}/auth/setNewPassword`;
            const body = JSON.stringify({newPassword,confirmPassword,userMail,token});
            const response = await sendPostRequest(url,body);
            const responseData = await response.json();

            if (!(response.status===201))
            {
                setIsLoading(false);
                setErrorMessage(responseData.message);
            }
            else
            {
                navigate("../login");
            }
        }
    };

    return (
        <Container page="NewPassword">
            <Header />
            <Box>
                {!isLoading && (
                    <form onSubmit={passwordChangeHandler} >
                        <Label>Reset Password</Label>
                        <InfoTag>New Password</InfoTag>
                        <Input
                            type="password"
                            name="newPassword"
                            value={newPassword}
                            Change={newPasswordChangeHandler}
                        />
                        <InfoTag>Confirm New Password</InfoTag>
                        <Input
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            Change={confirmPasswordChangeHandler}
                        />
                        {errorMessage && <ErrorInfo text={errorMessage} />}
                        <Button type="submit">Reset Password</Button>
                    </form>
                )}
                {isLoading && LoaderSpinner}
            </Box>
        </Container>
    );
};

export default NewPasswordPage;