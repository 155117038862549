import classes from "./Container.module.css";

const Container = props => {
    let addedClasses;
    if(props.page ==="Signup")
    {
        addedClasses = classes.container+" "+classes.signupBackground;
    }

    if (props.page ==="Login")
    {
        addedClasses = classes.container+" "+classes.loginBackground;
    }

    if (props.page ==="ResetPassword")
    {
        addedClasses = classes.container+" "+classes.resetPasswordBackground;
    }

    if (props.page==="NewPassword")
    {
        addedClasses = classes.container+" "+classes.newPasswordBackground;
    }

    return(
        <div className={addedClasses}>{props.children}</div>
    )
};


export default Container