import classes from "./PanelIconContainer.module.css";

const PanelIconContainer = (props) =>
{
    let cssClasses = classes.PanelIconContainer;
    if(props.isActive)
    {
        cssClasses = cssClasses+" "+classes.active;
    }
    return (
        <div className={cssClasses}>{props.children}</div>
    );
};

export default PanelIconContainer;