const formattedDateTime = (timeString) => {

    const parsedTimeString = timeString.split(":");
    const firstPart = parsedTimeString[0];
    const minute = parsedTimeString[1];

    const formattedDateTime = `${firstPart}:${minute}`;
    
    return formattedDateTime;
  };
  
export default formattedDateTime;