import classes from "./SettingButton.module.css";

const SettingButton = (props) => 
{
    let cssClasses = classes.SettingButton;
    cssClasses = (props.color === "red") ? `${cssClasses} ${classes.red}` : cssClasses;
    cssClasses = (props.color === "chocolate") ? `${cssClasses} ${classes.chocolate}` : cssClasses;
    cssClasses = (props.color === "darkgolden") ? `${cssClasses} ${classes.darkgolden}` : cssClasses;
    cssClasses = (props.color === "blue") ? `${cssClasses} ${classes.blue}` : cssClasses;
    return (
        <button 
            className={cssClasses} 
            onClick={props.onClick}>{props.text}</button>
    );
};

export default SettingButton;