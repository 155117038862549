import classes from "./MessageContentArea.module.css";
import React from "react";

const MessageContentArea = React.forwardRef((props, ref) => {
    return (
        <div 
            className={classes.MessageContentArea}
            ref={ref}
        >
            {props.children}
        </div>
    );
});

export default MessageContentArea;
