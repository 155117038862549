import MessageArea from "../Components/Message/UI/MessageArea/MessageArea";
import ExactlyCenteredDiv from "../Components/Common/Layouts/ExactlyCenteredDiv/ExactlyCenteredDiv";
import PageHeader from "../Components/Message/Elements/PageHeader/PageHeader";
import SettingLabel from "../Components/Setting/Elements/SettingLabel/SettingLabel";
import SettingInput from "../Components/Setting/Elements/SettingInput/SettingInput";
import SettingButton from "../Components/Setting/Elements/SettingButton/SettingButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import sendPostRequest from "../functions/sendPostRequest";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../Store/AuthenticationSlice";
import ErrorInfo from "../Components/Common/Elements/ErrorInfo/ErrorInfo";
import nullValidation from "../functions/nullValidation";
import Column from "../Components/Common/Layouts/Column/Column";
import { API_URL } from "../API_URL";

const ChangeUsernamePage = () => {
    const authenticatedUser = useSelector(state=>state.authenticatedUser);
    const userMail = useSelector(state=>state.userMail);
    const token = useSelector(state=>state.token);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [enteredPassword, setEnteredPassword ] = useState("");
    const [newUsername, setNewUsername] = useState("");
    const [error,setError] = useState("");

    const newUsernameChangeHandler = (event) =>{
        setNewUsername(event.target.value);
    };

    const passwordChangeHandler = (event) => {
        setEnteredPassword(event.target.value);
    };

    const changeUsernameHandler = async(event) =>{
        event.preventDefault();

        const areStatesNull = nullValidation([newUsername,enteredPassword]);

        if(areStatesNull)
        {
            setError("Please enter inputs.");
        }

        else
        {
            try
            {
                const url = `${API_URL}/user/changeUsername`;
                const body = JSON.stringify(
                    {
                        userId:authenticatedUser,
                        newUsername,
                        enteredPassword,
                        userMail,
                        token
                    });
                
                const response = await sendPostRequest(url,body);
                const responseData = await response.json();
            
                if(response.status===201)
                {
                    dispatch(authActions.setAuthenticatedUser(newUsername));
                    navigate("../settings");
                }
                else
                {
                    setError(responseData.message);
                }
            }
            catch (error) {
                setError(error);
            }
        }
    };

    return (
        <MessageArea>
            <ExactlyCenteredDiv>
                <form onSubmit={changeUsernameHandler}>
                    <Column>
                        <PageHeader header="Change Username" />
                        <SettingLabel text="Current Username" />
                        <SettingInput inputType="text" value={authenticatedUser} disable={true} />
                        <SettingLabel text="New Username" />
                        <SettingInput inputType="text" onChange={newUsernameChangeHandler} />
                        <SettingLabel text="Password" />
                        <SettingInput inputType="password" onChange={passwordChangeHandler} />
                        {error && <ErrorInfo text={error} />}
                        <SettingButton text="Change Username" color="darkgolden" />
                    </Column>
                </form>
            </ExactlyCenteredDiv>
        </MessageArea>
    );
};

export default ChangeUsernamePage;