import Label from "../Components/Auth/Elements/Label/Label";
import InfoTag from "../Components/Auth/Elements/InfoTag/InfoTag";
import Input from "../Components/Auth/Elements/Input/Input";
import Button from "../Components/Auth/Elements/Button/Button";
import Box from "../Components/Auth/UI/Box/Box";
import Container from "../Components/Auth/UI/Container/Container";
import Header from "../Components/Common/Elements/Header/Header";
import { useState } from "react";
import nullValidation from "../functions/nullValidation";
import sendPostRequest from "../functions/sendPostRequest";
import ResponseInfo from "../Components/Common/Elements/ErrorInfo/ErrorInfo";
import LoaderSpinner from "../Components/Common/Elements/LoaderSpinner/LoaderSpinner";
import { API_URL } from "../API_URL";

const ResetPasswordPage = () =>
{   
    const [email, setEmail] = useState("");
    const [responseMessage, setResponseMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const emailChangeHandler = (event) =>{
        setEmail(event.target.value);
    };

    const passwordResetHandler = async (event) =>
    {
        event.preventDefault();
        const isNull = nullValidation([email]);
        if(isNull)
        {
            alert("Please Enter Email");
        }
        else
        {
            setIsLoading(true);
            const url = `${API_URL}/auth/sendResetMail`;
            const body = JSON.stringify({userEmail:email});
            const response = await sendPostRequest(url,body);
            const responseData = await response.json();

            if(response.status===201)
            {
                setIsLoading(false);
                setResponseMessage(responseData.message);
            }
            else
            {
                setIsLoading(false);
                setResponseMessage(responseData.message);
            }
        }
    };

    return (
    <Container page="ResetPassword">
        <Header />
        <Box>
            {isLoading && <LoaderSpinner />}
            {!isLoading && (
                <form onSubmit={passwordResetHandler}>
                    <Label>Reset Password</Label>
                    <InfoTag>Email</InfoTag>
                    <Input
                        type="email"
                        name="email"
                        value={email}
                        Change={emailChangeHandler}
                    />
                    {responseMessage && <ResponseInfo text={responseMessage} />}
                    <Button type="submit">Reset Password</Button>
                </form>
            )}
        </Box>
    </Container>
    );
};

export default ResetPasswordPage;