import Header from "../Components/Common/Elements/Header/Header";
import Label from "../Components/Auth/Elements/Label/Label";
import Box from "../Components/Auth/UI/Box/Box";
import Container from "../Components/Auth/UI/Container/Container";
import InfoTag from "../Components/Auth/Elements/InfoTag/InfoTag";
import Input from "../Components/Auth/Elements/Input/Input";
import Button from "../Components/Auth/Elements/Button/Button";
import ErrorInfo from "../Components/Common/Elements/ErrorInfo/ErrorInfo";
import PageRedirect from "../Components/Auth/Elements/PageRedirect/PageRedirect";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {  useDispatch } from "react-redux";
import { authActions } from "../Store/AuthenticationSlice";
import sendPostRequest from "../functions/sendPostRequest";
import nullValidation from "../functions/nullValidation";
import {API_URL}  from "../API_URL";

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate(); 
    const dispatch = useDispatch();

    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    };

    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    };

    const loginHandler = async (event) => {
        event.preventDefault();
        
        const areStatesNull = nullValidation([email,password]);

        if(areStatesNull)
        {
            setError("Please enter inputs.");
        }
        
        else
        {
            try {
                const url = `${API_URL}/auth/login`;
                const credentials= "include";
                const body= JSON.stringify({email,password});

                const response = await sendPostRequest(url,body,credentials);
                const responseData = await response.json();
    
                if (response.status === 201) {
                    const token = responseData.token;
                    const username = responseData.username;
                    dispatch(authActions.login(username));
                    dispatch(authActions.setToken(token));
                    navigate("/message/homepage");
                } else {
                    setError(responseData.message);
                }
            } catch (error) {
                setError("An error occurred during login. Please try again later.");
            }
        }
    };

    return (
        <Container page="Login">
            <Header />
            <Box>
                <form onSubmit={loginHandler}>
                    <Label>Login</Label>
                    <InfoTag>Email</InfoTag>
                    <Input
                        type="email"
                        name="email"
                        value={email}
                        Change={emailChangeHandler}
                    />
                    <InfoTag>Password</InfoTag>
                    <Input
                        type="password"
                        name="password"
                        value={password}
                        Change={passwordChangeHandler}
                    />
                    {error && <ErrorInfo text={error} />}
                    <Button type="submit">Login</Button>
                    <PageRedirect text="Don't have an account?" link="Sign Up" linkHref="signup" />
                    <PageRedirect text="Forgot Password?" link="Reset Password" linkHref="reset_password" />
                </form>
            </Box>
        </Container>
    );
};

export default LoginPage;
