import classes from "./CurrentMessageProfile.module.css";

const CurrentMessageProfile = ({image}) =>
{   
    return (
        <div 
            className={classes.CurrentMessageProfile}
            style={{
            backgroundImage: `url(${image})`,
            }}
        ></div>
    );
};

export default CurrentMessageProfile;