const sendPostRequest = async(url,body, credentials="") =>{
    let response;
    if(credentials !== "")
    {
        response = await fetch(url,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: body
            });
    }
    
    else
    {
        response = await fetch(url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: body
            });
    }
    
    return response;
};

export default sendPostRequest;