import classes from "./MessageArea.module.css";

const MessageArea = (props) =>{
    return (
        <div 
            className={classes.MessageArea}
        >{props.children}</div>
    );
};


export default MessageArea;