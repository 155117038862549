import classes from "./SenderMessageContainer.module.css";

const SenderMessageContainer = (props) =>
{
    return (
        <div 
            className={classes.SenderMessageContainer}
            style={props.style}    
        >{props.children}</div>
    );
};

export default SenderMessageContainer;