import MessageArea from "../Components/Message/UI/MessageArea/MessageArea";
import ExactlyCenteredDiv from "../Components/Common/Layouts/ExactlyCenteredDiv/ExactlyCenteredDiv";
import Column from "../Components/Common/Layouts/Column/Column";
import PageHeader from "../Components/Message/Elements/PageHeader/PageHeader";
import SettingLabel from "../Components/Setting/Elements/SettingLabel/SettingLabel";
import UserProfile from "../Components/User/Elements/UserProfile/UserProfile";
import ImageInput from "../Components/Setting/Elements/ImageInput/ImageInput";
import SettingButton from "../Components/Setting/Elements/SettingButton/SettingButton";
import ErrorInfo from "../Components/Common/Elements/ErrorInfo/ErrorInfo";
import SettingInput from "../Components/Setting/Elements/SettingInput/SettingInput";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import sendPostRequest from "../functions/sendPostRequest";
import isValidImage from "../functions/isValidImage";
import { useDispatch } from "react-redux";
import { authActions } from "../Store/AuthenticationSlice";
import { API_URL } from "../API_URL";

const ChangePhotoPage = () => {
  const authenticatedUser = useSelector((state) => state.authenticatedUser);
  const token = useSelector((state) => state.token);
  const [userPhoto, setUserPhoto] = useState(null);
  const [password,setPassword] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserPhoto();
    
    // eslint-disable-next-line
  }, []);

  const getUserPhoto = async () => {
    const url = `${API_URL}/user/getUserProfileImageName`;
    const body = JSON.stringify({ user: authenticatedUser, token });

    const response = await sendPostRequest(url, body);
    let userImageName = "defaultAvatar.png";
    if (response.status === 201) {
      const responseData = await response.json();
      userImageName = responseData;
      const photoUrl = `${API_URL}/images/${userImageName}`;
      const photoResponse = await fetch(photoUrl);  

      const blob = await photoResponse.blob();
      const userProfilePhoto = URL.createObjectURL(blob);
      setUserPhoto(userProfilePhoto);
    }
  };

  const passwordChangeHandler = (event) =>{
    setPassword(event.target.value);
  };

  const imageChangeHandler = (event) =>
  {
    setSelectedImage(event.target.files[0]);
  }

  const imageSubmitHandler = async (event) => {
    event.preventDefault(); 

    if(password === "")
    {
        setError("Enter password.");
        return;
    }
  
    if (selectedImage) {
      const validate = isValidImage(selectedImage.name);
  
      if (validate) {
        const formData = new FormData();
        formData.append("username",authenticatedUser);
        formData.append("password",password);
        formData.append("token",token);
        formData.append("image", selectedImage);
  
        try 
        {
          const url = `${API_URL}/user/changeUserProfileImage`;
          const response = await fetch(url, {
            method: "POST",
            body: formData,
            headers: {'Authorization': `Bearer ${token}`}
          });
          
          const responseData = await response.json();

          if(response.status===201)
          {
            dispatch(authActions.logout());
          }

          else
          {
            setError(responseData.message);
          }
        } 
        catch (error) 
        {
          const errorMessage = error.message || "An error occurred";
          setError(errorMessage);
        }
      } else {
        setError("Enter a valid type of file.");
      }
    } else {
      setError("Select an image.");
    }
  };
  

  return (
    <MessageArea>
      <ExactlyCenteredDiv>
        <PageHeader header="Change Photo" />
        <SettingLabel text="Current Profile Photo" />
        <UserProfile image={userPhoto} />
        <form encType="multipart/form-data" onSubmit={imageSubmitHandler}>
            <Column>
                <SettingLabel text="Select New Profile Photo" />
                <ImageInput onChange={imageChangeHandler} />
                <SettingLabel text="Enter Password" />
                <SettingInput inputType="password" onChange={passwordChangeHandler} />
                {error && <ErrorInfo text={error} />}
                <SettingButton text="Change Photo" color="red"  />
            </Column>
        </form>
      </ExactlyCenteredDiv>
    </MessageArea>
  );
};

export default ChangePhotoPage;
