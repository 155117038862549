import classes from "./SendMessageButton.module.css";
import sendMessageIcon from "../../../../assets/icons/send_message_icon.svg";
const SendMessageButton = (props) =>
{
    return (
         <button className={classes.SendMessageButton} onClick={props.onClick} disabled={props.buttonDisabled}>
            <img className={classes.PanelIcon} src={sendMessageIcon} alt="Send Message" title="Send Message"></img>
         </button>
    );
};

export default SendMessageButton;