import Header from '../Components/Common/Elements/Header/Header';
import Label from '../Components/Auth/Elements/Label/Label';
import Box from '../Components/Auth/UI/Box/Box';
import Container from '../Components/Auth/UI/Container/Container';
import InfoTag from '../Components/Auth/Elements/InfoTag/InfoTag';
import Input from '../Components/Auth/Elements/Input/Input';
import Button from '../Components/Auth/Elements/Button/Button';
import PageRedirect from '../Components/Auth/Elements/PageRedirect/PageRedirect'; 
import ErrorInfo from "../Components/Common/Elements/ErrorInfo/ErrorInfo";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sendPostRequest from "../functions/sendPostRequest";
import nullValidation from "../functions/nullValidation";
import { API_URL } from '../API_URL';

const SignupPage = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const usernameChangeHandler = (event) =>{
        setUsername(event.target.value);
    }

    const emailChangeHandler = (event) =>{
        setEmail(event.target.value);
    }

    const passwordChangeHandler = (event) =>{
        setPassword(event.target.value);
    }

    const confirmPasswordChangeHandler = (event) =>{
        setConfirmPassword(event.target.value);
    }

    const signupHandler = async (event) => {
        event.preventDefault();

        const areStatesNull = nullValidation([username,email,password,confirmPassword])
        if (password !== confirmPassword) {
            setError("Passwords are not matched.");
            return;
        }
        
        if(areStatesNull)
        {
            setError("Please enter inputs.");
        }

        else
        {
            try {
                const url = `${API_URL}/auth/signup`;
                const body = JSON.stringify({username,email,password});
                
                const response = await sendPostRequest(url,body);
                const responseData = await response.json();
                if (response.status === 201) {
                    navigate("/");
                } else {
                    setError(responseData.message);
                }
            } catch (error) {
                setError("An error occurred during signup. Please try again later.");
            }
        }
    };

    return (
        <Container page="Signup">
            <Header />
            <Box>
                <form onSubmit={signupHandler}>
                    <Label>Sign Up</Label>
                    <InfoTag>Username</InfoTag>
                    <Input
                        type="text"
                        name="username"
                        Change={usernameChangeHandler}
                        value={username}
                    />
                    <InfoTag>Email</InfoTag>
                    <Input
                        type="email"
                        name="email"
                        Change={emailChangeHandler}
                    />
                    <InfoTag>Password</InfoTag>
                    <Input
                        type="password"
                        name="password"
                        Change={passwordChangeHandler}
                    />
                    <InfoTag>Confirm Password</InfoTag>
                    <Input
                        type="password"
                        name="confirmPassword"
                        Change={confirmPasswordChangeHandler}
                    />
                    {error && <ErrorInfo text={error} />}
                    <Button>Sign Up</Button>
                    <PageRedirect text="Do you have an account?" link="Login" linkHref="login" />
                </form>
            </Box>
        </Container>
    );
};

export default SignupPage;
