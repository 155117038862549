import classes from "./NewMessageBox.module.css";
import ErrorInfo from "../../../Common/Elements/ErrorInfo/ErrorInfo";
import MessageBox from "../MessageBox/MessageBox";
import MessageProfile from "../../Elements/MessageProfile/MessageProfile";
import MessageInfo from "../../Elements/MessageInfo/MessageInfo";
import UsernameLabel from "../../../User/Elements/UsernameLabel/UsernameLabel";
import MessageLabel from "../../Elements/MessageLabel/MessageLabel";

const NewMessageBox = (props) => {

    return(
        <div className={classes.NewMessageBoxContainer}>
            <h3 className={classes.NewMessageHeader}>New Receiver</h3>
                <form className={classes.NewMessageContainer} onSubmit={props.onSubmit}>
                    <input 
                        type="text" 
                        name="newMessageReceiver" 
                        placeholder="Search New Receiver"
                        value={props.value}
                        onChange={props.inputChange}
                        className={classes.NewMessageReceiverInput} />
                    <button 
                        type="submit"
                        className={classes.NewMessageSearchButton} >Search 🔍</button>
                </form>
                {!props.isReceiverExists && <ErrorInfo text={props.searchMessage} />}
                {props.isReceiverExists && props.show && (
                    <MessageBox onClick={props.onClick} >
                        <MessageProfile image={props.receiverImage} />
                        <MessageInfo >
                            <UsernameLabel user={props.receiverName} />
                            <MessageLabel />
                        </MessageInfo>
                    </MessageBox>
                )}
        </div>
    );
};

export default NewMessageBox;