import classes from "./SettingInput.module.css";

const SettingInput = (props) =>{
    return (
        <input 
            className={classes.SettingInput} 
            type={props.inputType}
            value={props.value}
            disabled={props.disable}
            onChange={props.onChange} />
    );
};

export default SettingInput;