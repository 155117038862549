import MessageArea from "../Components/Message/UI/MessageArea/MessageArea";
import PageHeader from "../Components/Message/Elements/PageHeader/PageHeader";
import ExactlyCenteredDiv from "../Components/Common/Layouts/ExactlyCenteredDiv/ExactlyCenteredDiv";
import UserProfile from "../Components/User/Elements/UserProfile/UserProfile";
import Row from "../Components/Common/Layouts/Row/Row";
import UserProfileInfoHeader from "../Components/User/Elements/UserProfileInfoHeader/UserProfileInfoHeader";
import UserProfileInfo from "../Components/User/Elements/UserProfileInfo/UserProfileInfo";
import SettingButton from "../Components/Setting/Elements/SettingButton/SettingButton";
import { useSelector,useDispatch } from "react-redux";
import sendPostRequest from "../functions/sendPostRequest";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authActions } from "../Store/AuthenticationSlice";
import { API_URL } from "../API_URL";

const SettingsPage = () =>{
    const authenticatedUser = useSelector(state=>state.authenticatedUser);
    const token = useSelector(state=>state.token);
    const userProfileMail = useSelector(state=>state.userMail);
    const [userProfilePhoto, setUserProfilePhoto] = useState(null);
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    useEffect(()=>{
        getAuthenticatedUserInfo();
    // eslint-disable-next-line
    },[]);
    
    const getAuthenticatedUserInfo = async() =>{
        const url = `${API_URL}/user/getAuthenticatedUserInfo`;
        const body = JSON.stringify({userId: authenticatedUser,token});
        const response = await sendPostRequest(url,body);
        const responseData = await response.json();
        const userImageName = responseData.userImageName;
        let userMail = "no mail found..."
        let userImage = "defaultAvatar.png";
        if(response.status === 201)
        {
            userImage= userImageName;
            userMail= responseData.userMail;
            dispatch(authActions.setUserMail(userMail));
        }
        const photoUrl = `${API_URL}/images/${userImage}`;
        const photoResponse = await fetch(photoUrl);  

        const blob = await photoResponse.blob();
        const userPhoto = URL.createObjectURL(blob);
        setUserProfilePhoto(userPhoto);
    };  

    const redirectPageHandler = (path) =>{
        navigate(path);
    }

    return (
        <MessageArea>
            <ExactlyCenteredDiv>
                <PageHeader header="Settings"/>
                <UserProfile image={userProfilePhoto}/>
                <Row>
                    <UserProfileInfoHeader text="Mail" />
                    <UserProfileInfo text={userProfileMail} />
                </Row>
                <Row>
                    <UserProfileInfoHeader text="Username" />
                    <UserProfileInfo text={authenticatedUser} />
                </Row>
                <Row>
                    <SettingButton text="Change Photo" color="red" onClick={() => redirectPageHandler("../changephoto")} />
                    <SettingButton text="Change Mail" color="chocolate" onClick={() => redirectPageHandler("../changemail")} />
                    <SettingButton text="Change Username" color="darkgolden" onClick={() => redirectPageHandler("../changeusername")}/>
                    <SettingButton text="Change Password" color="blue" onClick={() => redirectPageHandler("../changepassword")} />
                </Row>
            </ExactlyCenteredDiv>
        </MessageArea>
    );
};

export default SettingsPage;