import classes from "./MessageLabel.module.css";
import notificationTune from "../../../../assets/tunes/notificationTune.mp3";

const MessageLabel = (props) =>
{
    let notificationInfoCssClasses = "";
    if ((props.status === "sent") 
        && 
    (props.authenticatedUser !== props.lastMessageSender))
    {
        notificationInfoCssClasses = classes.NotificationInfo;
        const notificationAudio = new Audio(notificationTune)
        notificationAudio.play();
    }
    else
    {
        notificationInfoCssClasses +=classes.NotificationInfoHidden;
    }
    return (
        <div className={classes.MessageLabelDiv}>
            <p className={classes.MessageLabel}>{props.text}</p>
            <p 
                className={notificationInfoCssClasses}>●</p>
        </div>
    );
};

export default MessageLabel;