import classes from "./MessageProfile.module.css";

const MessageProfile = ({image}) =>
{
    return(
        <div 
            className={classes.MessageProfile}
            style={{
                backgroundImage: `url(${image})`,
            }}
        ></div>
    );
};

export default MessageProfile;