const isValidImage = (filename) => {
    const fileExtension = filename.split(".")[filename.split(".").length-1];
    console.log(fileExtension);
    const allowedImageTypes = ["jpeg", "png", "jpg", "ico"];

    return filename && allowedImageTypes.includes(fileExtension);
};

export default isValidImage;

