import { createBrowserRouter, useNavigate } from "react-router-dom";
import SignupPage from "../Pages/SingupPage";
import LoginPage from "../Pages/LoginPage";
import ErrorPage from "../Pages/ErrorPage";
import ResetPasswordPage from "../Pages/ResetPasswordPage";
import MessagesPage from "../Pages/MessagesPage";
import Homepage from "../Pages/HomePage";
import SettingsPage from "../Pages/SettingsPage";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import RedirectPage from "../Pages/RedirectPage";
import MessageLayout from "../Pages/MessageLayout";
import ChangeMailPage from "../Pages/ChangeMailPage";
import ChangeUsernamePage from "../Pages/ChangeUsernamePage";
import ChangePasswordPage from "../Pages/ChangePasswordPage";
import ChangePhotoPage from "../Pages/ChangePhotoPage";
import NewPasswordPage from "../Pages/NewPasswordPage";

const ProtectedRoute = ({ element }) => {
    let isAuthenticated = useSelector(state => state.isAuthenticated);
    const token = useSelector(state=> state.token);
    const navigate = useNavigate();
    if(token!== null)
    {
        isAuthenticated = true;
    }
    
    useEffect(() => {
      if (!isAuthenticated) {
        return navigate("/auth/login");
      }
    }, [isAuthenticated, navigate]);
  
    return element;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <RedirectPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "auth",
        children: [
            {
                path: "signup",
                element: <SignupPage />,
            },
            {
                path: "login",
                element: <LoginPage />
            },
            {
                path:"reset_password",
                element: <ResetPasswordPage />
            },
            {
                path:"new_password",
                element: <NewPasswordPage />
            }
        ]
    },
    {
        path: "message",
        element: <ProtectedRoute element={<MessageLayout />} />,
        children: [
            {
                path: "homepage",
                element: <ProtectedRoute element={<Homepage />} />,
            },
            {
                path:"settings",
                element: <ProtectedRoute element={<SettingsPage />}/>,
            },
            {
                path: "changephoto",
                element: <ProtectedRoute element={<ChangePhotoPage />}/>
            },
            {
                path: "changemail",
                element: <ProtectedRoute element={<ChangeMailPage />}/>
            },
            {
                path:"changeusername",
                element: <ProtectedRoute element={<ChangeUsernamePage />}/>
            },
            {
                path: "changepassword",
                element:  <ProtectedRoute element={<ChangePasswordPage />}/>
            },
            {
                path: "messages",
                element: <ProtectedRoute element={<MessagesPage />} />
            },
            {
                path: "messages/receiver/:receiverId",
                element: <ProtectedRoute element={<MessagesPage />} />,
            },
        ]
    }
]);

export default router;