import classes from "./MessageContentTime.module.css";

const MessageContentTime = (props) =>
{
    let statusContent="";
    if(props.status === "sent")
    {
        statusContent = "✓";
    }

    if(props.status  === "seen")
    {
        statusContent="✓✓";
    }
    
    const content = props.time+" "+statusContent;

    return (
        <p className={classes.MessageContentTime}>{content}</p>
    );
};

export default MessageContentTime;