import MessageArea from "../Components/Message/UI/MessageArea/MessageArea";
import ExactlyCenteredDiv from "../Components/Common/Layouts/ExactlyCenteredDiv/ExactlyCenteredDiv";
import PageHeader from "../Components/Message/Elements/PageHeader/PageHeader";
import SettingLabel from "../Components/Setting/Elements/SettingLabel/SettingLabel";
import SettingInput from "../Components/Setting/Elements/SettingInput/SettingInput";
import SettingButton from "../Components/Setting/Elements/SettingButton/SettingButton";
import Column from "../Components/Common/Layouts/Column/Column";
import ErrorInfo from "../Components/Common/Elements/ErrorInfo/ErrorInfo";
import { useSelector,useDispatch } from "react-redux";
import { useState } from "react";
import sendPostRequest from "../functions/sendPostRequest";
import { authActions } from "../Store/AuthenticationSlice";
import nullValidation from "../functions/nullValidation";
import { API_URL } from "../API_URL";

const ChangePasswordPage = (props) =>{
    const userId = useSelector(state=>state.authenticatedUser);
    const userMail = useSelector(state=>state.userMail);
    const token = useSelector(state=>state.token);
    
    const [currentPassword,setCurrentPassword] = useState("");
    const [newPassword,setNewPassword] = useState("");
    const [confirmNewPassword,setConfirmNewPassword] = useState("");
    const [error,setError] = useState("");
    const dispatch = useDispatch();

    const currentPasswordChangeHandler = (event) => {
        setCurrentPassword(event.target.value);
    };

    const newPasswordChangeHandler = (event) => {
        setNewPassword(event.target.value);
    };

    const confirmNewPasswordChangeHandler = (event) =>{
        setConfirmNewPassword(event.target.value);
    };

    const changePasswordHandler = async(event)=>{
        event.preventDefault();
        
        const areStatesNull = nullValidation(currentPassword,newPassword,confirmNewPassword);
        if(areStatesNull)
        {
            setError("Please enter inputs.");
        }

        else
        {
            try
            {
                const url = `${API_URL}/user/changeUserPassword`;
                const body = JSON.stringify({
                    userId,
                    userMail,
                    currentUserPassword: currentPassword,
                    newUserPassword: newPassword,
                    confirmNewUserPassword: confirmNewPassword,
                    token
                });

                const response = await sendPostRequest(url,body);
                const responseData = await response.json();

                if(response.status===201)
                {
                    dispatch(authActions.logout());
                }
                else
                {
                    setError(responseData.message);
                }
            }
            catch(error)
            {
                console.error(error);
            }
        }
    };
    return(
        <MessageArea>
            <ExactlyCenteredDiv>
                <form onSubmit={changePasswordHandler}>
                    <Column>
                        <PageHeader header="Change Password" />
                        <SettingLabel text="Current Password" />
                        <SettingInput inputType="password" onChange={currentPasswordChangeHandler} />
                        <SettingLabel text="New Password Password" />
                        <SettingInput inputType="password" onChange={newPasswordChangeHandler} />
                        <SettingLabel text="Confirm New Password" />
                        <SettingInput inputType="password" onChange={confirmNewPasswordChangeHandler}/>
                        {error && <ErrorInfo text={error} />}
                        <SettingButton text="Change Password" color="blue" />
                    </Column>
                </form>
            </ExactlyCenteredDiv>
        </MessageArea>
    );
};

export default ChangePasswordPage;