import classes from "./CurrentMessageReceiverStatus.module.css";

const CurrentMessageReceiverStatus = (props) =>
{
    let cssClasses = classes.CurrentMessageReceiverStatus+" ";
    if(props.status === "Online")
    {
        cssClasses+= classes.Online;
    }

    if(props.status === "Offline")
    {
        cssClasses+= classes.Offline;
    }
    
    return (
        <p className={cssClasses}>{props.status}</p>
    );
};

export default CurrentMessageReceiverStatus;