import Wrapper from "../Components/Message/UI/Wrapper/Wrapper";
import MessageBar from "../Components/Message/UI/MessageBar/MessageBar";
import MessageBox from "../Components/Message/UI/MessageBox/MessageBox";
import MessageProfile from "../Components/Message/Elements/MessageProfile/MessageProfile";
import MessageInfo from "../Components/Message/Elements/MessageInfo/MessageInfo";
import MessageLabel from "../Components/Message/Elements/MessageLabel/MessageLabel";
import UsernameLabel from "../Components/User/Elements/UsernameLabel/UsernameLabel";
import SettingsBox from "../Components/Message/UI/SettingsBox/SettingsBox";
import PanelIconContainer from "../Components/Message/UI/PanelIconContainer/PanelIconContainer";
import PanelIcon from "../Components/Message/Elements/PanelIcon/PanelIcon";
import SettingsIcon from "../assets/icons/settings_icon.svg";
import HomeIcon from "../assets/icons/home_icon.svg";
import LogoutIcon from "../assets/icons/logout_icon.svg";
import { useDispatch, useSelector} from "react-redux";
import { authActions } from "../Store/AuthenticationSlice";
import { Outlet,useNavigate, useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import sendPostRequest from "../functions/sendPostRequest";
import io from "socket.io-client";
import generateUniqueId from "../functions/generateUniqueId";
import NewMessageBox from "../Components/Message/UI/NewMessageBox/NewMessageBox";
import nullValidation from "../functions/nullValidation";
import { API_URL } from "../API_URL";

const MessageLayout = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [error,setIsError] = useState(false);
    const authenticatedUser = useSelector(state=>state.authenticatedUser);
    const [messageBarContent, setMessageBarContent] = useState(null);
    const [lastPath, setLastPath] = useState("homepage");
    const token = useSelector(state =>state.token);
    const isMessageChanged = useSelector(state =>state.isMessageChanged);
    const [newReceiver,setNewReceiver] = useState("");
    const [ newReceiverPhoto, setNewReceiverPhoto ] = useState(null);
    const [isUserExists, setIsUserExists] = useState(null);
    const [userExistsMessage,setUsereExistsMessage] = useState("");
    const [showNewReceiver,setShowNewReceiver] = useState(false);

    useEffect(()=>{
        const socket = io(`${API_URL}`);
        
        socket.on(`${authenticatedUser}Logout`,async(status)=>{
            if(authenticatedUser !== "" && status==="Offline")
            {
                dispatch(authActions.logout());
            }
        });

        const disconnectHandler = () =>{
            socket.emit("userDisconnect",authenticatedUser);
            socket.disconnect();
        };

        window.addEventListener("beforeunload",disconnectHandler);

        return () =>{
            socket.disconnect();
            window.removeEventListener("beforeunload",disconnectHandler);
        };
        // eslint-disable-next-line
    },[]);

    useEffect(()=>{
        const socket = io(`${API_URL}`);
        socket.on(`${authenticatedUser}ReceivedMessage`, (data) => {
            dispatch(authActions.setIsMessageChanged(!isMessageChanged));
        });

        getLastPath();
        
        const fetchData = async() =>
        {
            await getInteractivedUsers();
        }

        fetchData();

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line
    },[location, isMessageChanged]);

      const getInteractivedUsers = async () => {
        const url = `${API_URL}/user/getInteractivedUsers`;
        const body = JSON.stringify({ user: authenticatedUser, token });
        const response = await sendPostRequest(url, body);
        if (response.status === 201) {
            const responseData = Array.from(await response.json());
            const content = await Promise.all(
                responseData.map(async (user) => {
                    const lastMessageInfo = await getLastMessage(authenticatedUser, user);
                    const lastMessageWithUser = lastMessageInfo.lastMessage;
                    const lastMessageStatus = lastMessageInfo.lastMessageStatus;
                    const lastMessageSender = lastMessageInfo.lastMessageSender;
                    const userImageName = await getReceiverProfileImage(user);
                    const photoUrl = `${API_URL}/images/${userImageName}`;
                    const photoResponse = await fetch(photoUrl);  

                    const blob = await photoResponse.blob();
                    const userImage = URL.createObjectURL(blob);
                    return (
                        <MessageBox key={generateUniqueId()} onClick={() => redirectMessagePage(user)}>
                            <MessageProfile key={generateUniqueId()} image={userImage} />
                            <MessageInfo key={generateUniqueId()}>
                                <UsernameLabel user={user} key={generateUniqueId()} />
                                <MessageLabel 
                                    text={lastMessageWithUser} 
                                    key={generateUniqueId()} 
                                    status={lastMessageStatus}
                                    authenticatedUser={authenticatedUser}
                                    lastMessageSender={lastMessageSender}
                                />
                            </MessageInfo>
                        </MessageBox>
                    );
                })
            );
    
            setMessageBarContent(content);
            return;
        } else {
            setIsError(true);
            return;
        }
    };
    
    

    const getReceiverProfileImage = async(receiver) =>
    {   
        const url = `${API_URL}/user/getUserProfileImageName`;
        const body = JSON.stringify({user: receiver,token});

        const response = await sendPostRequest(url,body);

        const userProfileName = await response.json();
        return userProfileName;
    }; 

    const getLastMessage = async(sender,receiver) =>{
        const url = `${API_URL}/message/getLastMessage`;
        const body = JSON.stringify({sender,receiver,token});

        const response = await sendPostRequest(url,body);

        const getLastMessageFromResponse = await response.json(); 
        return getLastMessageFromResponse; 
    };

    const getLastPath = () => {
        const locationPathname = location.pathname;
        const locationLastPath = locationPathname.split("/")[2];
        return setLastPath(locationLastPath);
    };
    
    
    const redirectMessagePage = (receiver) => {
        navigate(`messages/receiver/${receiver}`);
        hideNewReceiver();
        setNewReceiver("");
    };
    
    const homepageHandler = () =>
    {
        navigate("homepage");
    };

    const settingsPageHandler = () =>
    {
        navigate("settings");
    };

    const logoutHandler = async()=> {
        const url = `${API_URL}/auth/logout`;
        const body = JSON.stringify({username:authenticatedUser});

        const response = await sendPostRequest(url,body);
        if(response.status===201)
        {
            dispatch(authActions.logout());
        }
    
    };

    const newReceiverChangeHandler = (event) =>
    {
        setNewReceiver(event.target.value);
    };

    const findNewReceiver = async (event) => {
        event.preventDefault();
        const isNull = nullValidation([newReceiver]);

        if(isNull)
        {
            alert("Please Enter Receiver Name");
            setNewReceiver("");
            setShowNewReceiver(true);
        }
        else
        {
            const url = `${API_URL}/user/checkUser`;
            const body = JSON.stringify({sender: authenticatedUser,username:newReceiver,token});
            const response = await sendPostRequest(url,body);

            const responseData = await response.json();

            setIsUserExists(responseData.user);

            if(responseData.user)
            {
                const userImageName = await getReceiverProfileImage(newReceiver); 
                const photoUrl = `${API_URL}/images/${userImageName}`;
                const photoResponse = await fetch(photoUrl);  

                const blob = await photoResponse.blob();
                const userPhoto = URL.createObjectURL(blob);
                setNewReceiverPhoto(userPhoto);
                setShowNewReceiver(true);
            }
            else
            {
                const searchMessage = responseData.message;
                setUsereExistsMessage(searchMessage);
                setShowNewReceiver(true);
            }
        }
    };

    const hideNewReceiver = () => {
        setShowNewReceiver(false);
    };

    return (
        <Wrapper onMouseDown={hideNewReceiver}>
            <MessageBar>
                <NewMessageBox 
                    onSubmit={findNewReceiver} 
                    onClick={() => redirectMessagePage(newReceiver)}
                    value={newReceiver}
                    inputChange={newReceiverChangeHandler}
                    isReceiverExists={isUserExists}
                    searchMessage={userExistsMessage}
                    receiverName={newReceiver}
                    receiverImage={newReceiverPhoto}
                    show={showNewReceiver} />
                {!error && messageBarContent}
                <SettingsBox>
                    <PanelIconContainer isActive={lastPath==="settings"}>
                        <PanelIcon src={SettingsIcon} alt="Settings Icon" title="Settings" onClick={settingsPageHandler} />
                    </PanelIconContainer>
                    <PanelIconContainer isActive={lastPath==="homepage"} >
                        <PanelIcon src={HomeIcon} alt="Home Icon" title="Home" onClick={homepageHandler} />
                    </PanelIconContainer>
                    <PanelIconContainer isActive={false}>
                        <PanelIcon src={LogoutIcon} alt="Logout Icon" onClick={logoutHandler} title="Logout" />
                    </PanelIconContainer>
                </SettingsBox>
            </MessageBar>
            <Outlet />
        </Wrapper>
    );
};

export default MessageLayout;