import ExactlyCenteredDiv from "../Components/Common/Layouts/ExactlyCenteredDiv/ExactlyCenteredDiv";
import PageHeader from "../Components/Message/Elements/PageHeader/PageHeader";
import MessageArea from "../Components/Message/UI/MessageArea/MessageArea";
import SettingInput from "../Components/Setting/Elements/SettingInput/SettingInput";
import SettingLabel from "../Components/Setting/Elements/SettingLabel/SettingLabel";
import SettingButton from "../Components/Setting/Elements/SettingButton/SettingButton";
import { useSelector,useDispatch } from "react-redux";
import { useState } from "react";
import sendPostRequest from "../functions/sendPostRequest";
import { authActions } from "../Store/AuthenticationSlice";
import { useNavigate } from "react-router-dom";
import ErrorInfo from "../Components/Common/Elements/ErrorInfo/ErrorInfo";
import nullValidation from "../functions/nullValidation";
import Column from "../Components/Common/Layouts/Column/Column";
import { API_URL } from "../API_URL";

const ChangeMailPage = () =>{
    const userMail = useSelector(state=>state.userMail);
    const token = useSelector(state=>state.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [newEmail,setNewEmail] = useState("");
    const [password,setPassword] = useState("");
    const [error, setError] = useState("");
    
    const emailChangeHandler = (event) =>{
        setNewEmail(event.target.value);
    };

    const passwordChangeHandler = (event) =>{
        setPassword(event.target.value);
    };

    const changeMailHandler = async(event) => {
        event.preventDefault();
        const areStatesNull = nullValidation([newEmail,password]);

        if(areStatesNull)
        {
            setError("Please enter inputs.");
        }
        else
        {
            try
            {
                const url = `${API_URL}/user/changeUserMail`;
                const body = JSON.stringify(
                    {
                        currentUserMail:userMail,
                        newUserMail:newEmail,
                        enteredPassword:password,
                        token
                    });

                const response = await sendPostRequest(url,body);
                const responseData = await response.json();
                
                if(response.status===201)
                {
                    dispatch(authActions.setUserMail(newEmail));
                    navigate("../settings");
                }
                else
                {
                    setError(responseData.message);
                }

            }
            catch(error)
            {
                console.error(error);
            }
        }
    };

    return (
        <MessageArea>
            <ExactlyCenteredDiv>
                <form onSubmit={changeMailHandler}>
                    <Column>
                        <PageHeader header="Change Mail" />
                        <SettingLabel text="Current Email" />
                        <SettingInput inputType="email" value={userMail} disable={true} />
                        <SettingLabel text="New Email" />
                        <SettingInput inputType="email" onChange={emailChangeHandler} />
                        <SettingLabel text="Password" />
                        <SettingInput inputType="password" onChange={passwordChangeHandler} />
                        {error && <ErrorInfo text={error} />}
                        <SettingButton text="Change Mail" color="chocolate" />
                    </Column>
                </form>
            </ExactlyCenteredDiv>
        </MessageArea>
    );
};

export default ChangeMailPage;