import classes from "./Button.module.css";

const Button = props =>{
    return (
        <div>
            <button className={classes.Button}>{props.children}</button>
        </div>
    );
};

export default Button;