import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    authenticatedUser: null,
    token: null,
    userMail: null,
    isMessageChanged:false,
}

const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers:
    {
        login(state,action){
            state.isAuthenticated = true;
            state.authenticatedUser = action.payload;
            setTimeout(()=>{
                localStorage.removeItem("token");
                window.location.reload();
            }, (2*60*60*1000));
        },
        logout(state,action){
            state.isAuthenticated = false;
            state.token = null;
            state.userMail = null;
            state.authenticatedUser = "";
        },
        setToken(state,action){
            state.token = action.payload;
        },
        setUserMail(state,action)
        {
            state.userMail = action.payload;
        },
        setAuthenticatedUser(state,action)
        {
            state.authenticatedUser = action.payload;
        },
        setIsMessageChanged(state,action)
        {
            state.isMessageChanged = action.payload;
        },
    }
});

export const authActions = authenticationSlice.actions;
export default authenticationSlice;