import { ClipLoader } from "react-spinners";
import classes from "./LoaderSpinner.module.css";

const LoaderSpinner = (props) => {
  return (
    <div className={classes.LoaderSpinner}>
      <ClipLoader color={"#00FBFF"} size={100} loading={props.loading}/>
    </div>
  );
};

export default LoaderSpinner;
