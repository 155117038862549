import MessageArea from "../Components/Message/UI/MessageArea/MessageArea";
import PageHeader from "../Components/Message/Elements/PageHeader/PageHeader";
import PageInformation from "../Components/Message/Elements/PageInformation/PageInformation";
import ContactLink from "../Components/Message/Elements/ContactLink/ContactLink";
import ExactlyCenteredDiv from "../Components/Common/Layouts/ExactlyCenteredDiv/ExactlyCenteredDiv";

const Homepage = (props) =>
{   
    return (
        <MessageArea>
            <ExactlyCenteredDiv>
                <PageHeader header="Homepage" />
                <PageInformation text="This application developed by " spanText="zThyphon" />
                <PageInformation text="Contact Addresses"/>
                <ContactLink href="mailto:mustafacil198@gmail.com" text="Email" />
                <ContactLink href="https://www.linkedin.com/in/mustafa-doruk-%C3%A7il-602a33217/" text="LinkedIn" />
            </ExactlyCenteredDiv>
        </MessageArea>
    );
};

export default Homepage;