import { Fragment } from "react";
import classes from "./Header.module.css";

const Header = () => {
    return(
        <Fragment>
            <h2 className={classes.header}>
            <span className={classes.headerChat}>Chat </span>
            <span className={classes.headerTo}>to </span>
            <span className={classes.headerMe}>Me</span></h2>
        </Fragment>
    );
};

export default Header;