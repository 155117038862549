import classes from "./PanelIcon.module.css";

const PanelIcon = (props) =>
{
    return (
        <img 
            className={classes.PanelIcon} 
            src={props.src} 
            alt={props.alt} 
            title={props.title}
            onClick={props.onClick} />
    );
};

export default PanelIcon;