import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const RedirectPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/message/messages");

        // eslint-disable-next-line
    }, []);
    return null;
};

export default RedirectPage;
