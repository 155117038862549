import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import MessageArea from "../Components/Message/UI/MessageArea/MessageArea";
import MessageHeaderArea from "../Components/Message/UI/MessageHeaderArea/MessageHeaderArea";
import CurrentMessageProfile from "../Components/Message/Elements/CurrentMessage/CurrentMessageProfile";
import CurrentMessageInfoBox from "../Components/Message/Elements/CurrentMessage/CurrentMessageInfoBox";
import CurrentMessageReceiver from "../Components/Message/Elements/CurrentMessage/CurrentMessageReceiver";
import CurrentMessageReceiverStatus from "../Components/Message/Elements/CurrentMessage/CurrentMessageReceiverStatus";
import MessageContentArea from "../Components/Message/UI/MessageContentArea/MessageContentArea";
import SenderMessageContent from "../Components/Message/Elements/SenderMessageContent/SenderMessageContent";
import ReceiverMessageContainer from "../Components/Message/UI/ReceiverMessageContainer/ReceiverMessageContainer";
import ReceiverMessageContent from "../Components/Message/Elements/ReceiverMessageContent/ReceiverMessageContent";
import SendMessageContainer from "../Components/Message/UI/SendMessageContainer/SendMessageContainer";
import MessageInput from "../Components/Message/Elements/MessageInput/MessageInput";
import SendMessageButton from "../Components/Message/Elements/SendMessageButton/SendMessageButton";
import SenderMessageContainer from "../Components/Message/UI/SenderMessageContainer/SenderMessageContainer";
import MessageContentTime from "../Components/Message/Elements/MessageContentTime/MessageContentTime";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import sendPostRequest from "../functions/sendPostRequest";
import { authActions } from "../Store/AuthenticationSlice";
import nullValidation from "../functions/nullValidation";
import formattedDateTime from "../functions/formattedDateTime";
import generateUniqueId from "../functions/generateUniqueId";
import io from "socket.io-client";
import LoaderSpinner from "../Components/Common/Elements/LoaderSpinner/LoaderSpinner";
import { API_URL } from "../API_URL";

const MessagesPage = () => {
  const [message, setMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [messageContent, setMessageContent] = useState(false);
  const [messageInfo, setMessageInfo] = useState(true);
  const authenticatedUser = useSelector((state) => state.authenticatedUser);
  const isMessageChanged = useSelector((state) => state.isMessageChanged);
  const token = useSelector((state) => state.token);
  const { receiverId } = useParams();
  const [receiverStatus, setReceiverStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const dispatch = useDispatch();
  const messageContentAreaRef = useRef(null);

  useEffect(() => {
    const socket = io(`${API_URL}`);
    socket.emit("changeMessageStatus", {
      receiver: authenticatedUser,
      sender: receiverId,
      type: "useEffect",
    });
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, [receiverId,isMessageChanged]);


  useEffect(() => {
    const socket = io(`${API_URL}`);
  
    socket.on(`${receiverId}MessageSeenInfo`, () => {
      const fetchData = async () => {
        await getMessages();
        setMessageInfo(!messageInfo);
      };
  
      fetchData();
    });
  
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, [receiverId, isMessageChanged, messageInfo]);

  
  useEffect(() => {
    const socket = io(`${API_URL}`);
    setMessage("");
    socket.on(`${receiverId}Message`, (data) => {
      dispatch(authActions.setIsMessageChanged(!isMessageChanged));
      setMessageInfo(!messageInfo);
    });

    const fetchData = async () => {
      setIsLoading(true);
      await getReceiverProfileImage();
      await getReceiverStatus();
      await getMessages();
      setMessageInfo(!messageInfo);
      setIsLoading(false);
    };

    fetchData();
    
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, [receiverId, isMessageChanged]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getMessages("renderState");
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (messageContentAreaRef.current) {
      messageContentAreaRef.current.scrollTop =
        messageContentAreaRef.current.scrollHeight;
    }
  }, [isMessageChanged,messageContent]);

  const messageChangeHandler = (event) => {
    setMessage(event.target.value);
    setButtonDisabled(event.target.value.length === 0);

  };

  const sendMessageHandler = async (event) => {
    event.preventDefault();

    const isNull = nullValidation(message);

    if (!isNull) {
      const sender = authenticatedUser;
      const status = "sent";

      const url = `${API_URL}/message/sendMessage`;
      const credentials = "include";
      const body = JSON.stringify({
        sender,
        receiver: receiverId,
        content: message,
        status,
        token,
      });

      const response = await sendPostRequest(url, body, credentials);

      if (response.status === 201) {
        dispatch(authActions.setIsMessageChanged(!isMessageChanged));
      }
    }
  };

  const getMessages = async (calledState) => {
    const senderId = authenticatedUser;
    const url = `${API_URL}/message/getMessages`;
    const body = JSON.stringify({ senderId, receiverId, token });

    const response = await sendPostRequest(url, body);

    const responseData = Array.from(await response.json());
    let content = (
      <MessageContentArea ref={messageContentAreaRef}>
        {responseData.map((element) => {
          const key = generateUniqueId();
          const timeKey = generateUniqueId();

          if (element.sender === authenticatedUser) {
            return (
              <SenderMessageContainer key={key}>
                <SenderMessageContent message={element.content} key={key} />
                <MessageContentTime
                  key={timeKey}
                  time={formattedDateTime(element.created_at)}
                  status={element.status}
                />
              </SenderMessageContainer>
            );
          }

          if (element.receiver === authenticatedUser) {
            return (
              <ReceiverMessageContainer key={key}>
                <ReceiverMessageContent message={element.content} key={key} />
                <MessageContentTime
                  key={timeKey}
                  time={formattedDateTime(element.created_at)}
                  status={element.status}
                />
              </ReceiverMessageContainer>
            );
          }

          return null;
        })}

        {calledState === "isMessageChanged" && scrollDownDiv()}
      </MessageContentArea>
    );

    setMessageContent(content);
  };

  const scrollDownDiv = () => {
    return (
      <SenderMessageContainer key={generateUniqueId()}>
        <SenderMessageContent
          message="Message is sending..."
          key={generateUniqueId()}
        />
        <MessageContentTime
          key={generateUniqueId()}
          time="time"
          status="status"
        />
      </SenderMessageContainer>
    );
  };

  const getReceiverProfileImage = async () => {
    setIsLoading(true);
    const url = `${API_URL}/user/getUserProfileImageName`;
    const body = JSON.stringify({ user: receiverId, token });

    const response = await sendPostRequest(url, body);

    const userProfileName = await response.json();
    const photoUrl = `${API_URL}/images/${userProfileName}`;
    const photoResponse = await fetch(photoUrl);  
    const blob = await photoResponse.blob();
    setUserPhoto(URL.createObjectURL(blob));
    setIsLoading(false);
  };

  const getReceiverStatus = async () => {
    const url = `${API_URL}/user/getUserStatus`;
    const body = JSON.stringify({ receiverId, token });

    const response = await sendPostRequest(url, body);

    const responseData = await response.json();
    const obtainedReceiverStatus = responseData.status;
    setReceiverStatus(obtainedReceiverStatus);
  };

  return (
    <MessageArea>
      <MessageHeaderArea>
        <CurrentMessageProfile
          image={userPhoto}
        />
        <CurrentMessageInfoBox>
          <CurrentMessageReceiver user={receiverId} />
          <CurrentMessageReceiverStatus status={receiverStatus} />
        </CurrentMessageInfoBox>
      </MessageHeaderArea>
      {isLoading && <LoaderSpinner loading={isLoading} />}
      {!isLoading && messageContent}
      {!isLoading &&
      <SendMessageContainer>
        <MessageInput onChange={messageChangeHandler} value={message} />
        <SendMessageButton
          onClick={sendMessageHandler}
          buttonDisabled={buttonDisabled}
        />
      </SendMessageContainer>
      }
    </MessageArea>
  );
};

export default MessagesPage;
