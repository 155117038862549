import classes from "./Input.module.css";

const Input = (props) =>
{
    return (
        <input 
            className={classes.Input} 
            type={props.type}
            name={props.name} 
            onChange={props.Change}/>
    );
};

export default Input;