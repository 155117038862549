import classes from "./ImageInput.module.css";

const ImageInput = (props) => {
    return (
        <input 
            className={classes.ImageInput} 
            type="file" 
            name="image"  
            accept="image/*"
            onChange={props.onChange} />
    );
};

export default ImageInput;