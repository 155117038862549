import classes from "./ContactLink.module.css";

const ContactLink = (props) => {
    return (
        <a className={classes.ContactLink} href={props.href} target="_blank" rel="noreferrer">
            <p>{props.text}</p>
        </a>
    );
};

export default ContactLink;